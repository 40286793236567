import React      from "react";
import { Helmet } from "react-helmet"

import Layout   from '../components/layout';
import Headline from '../components/atoms/headline/headline';
import Text     from '../components/atoms/text/text';
import Stage    from '../components/molecules/stage/stage';

export default ({ data }) => (
  <Layout siteTitle="ChancenCheckin">
    <Helmet
      htmlAttributes={{
        lang: 'de'
      }}
    >
      <meta charSet="utf-8" />
      <title>Page not found - ChancenCheckin</title>
      <meta name="description" content="Dein Geschäft befindet sich in der Krise? ChancenCheckin –  vielfältige Möglichkeiten für Deine unternehmerische Herausforderung. Schnell. Einfach. Individuell." />
      <link rel="canonical" href="https://www.chancencheckin.de/404" />
    </Helmet>
    <main style={{flex: 1}}>
      <Stage fullHeight={true} fullWidth={false} alignVert="middle">
        <Headline>404 - Seite nicht gefunden</Headline>
        <Text>Huch! Da ist wohl was schief gelaufen.</Text>
      </Stage>
    </main>
  </Layout>
)
